import {
  IWixStyleFont,
  ISettingsFont,
} from '@wix/tpa-settings';
import { IStylesParamsFontsKeys } from '../../src/components/BookOnline/stylesParams';
import { MobileSettingsKeys, SettingsKeys } from '../types';
import { ServiceListSettings } from './appSettings';
import { cleanNulls } from '../utils';

const stylesParamFontKeyToAppSettingsMapper: {
  [key in IStylesParamsFontsKeys]: SettingsKeys | MobileSettingsKeys;
} = {
  widgetTitleFont: SettingsKeys.MULTI_OFFERINGS_TITLE_FONT,
  filterTitleFont: SettingsKeys.CATEGORY_NAME_FONT,
  dropdownTextFont: MobileSettingsKeys.MOBILE_CATEGORY_NAME_FONT,
  serviceNameFont: SettingsKeys.OFFERING_NAME_FONT,
  serviceTagLineFont: SettingsKeys.OFFERING_TAGLINE_FONT,
  moreInfoButtonFont: SettingsKeys.OFFERING_MORE_INFO_LABEL_FONT,
  serviceDetailsFont: SettingsKeys.OFFERING_DETAILS_FONT,
  courseAvailabilityFont: SettingsKeys.COURSE_AVAILABILITY_FONT,
  bookButtonFont: SettingsKeys.BUTTON_TEXT_FONT,
};

export function mergeAppSettingsNumbersToStyleParamsFonts(
  appSettings: ServiceListSettings,
  fonts?: { [key: string]: IWixStyleFont },
) {
  function mapFont({
    appSettingsFont,
    styleParamsFontKey,
  }: {
    appSettingsFont?: ISettingsFont;
    styleParamsFontKey: IStylesParamsFontsKeys;
  }): ISettingsFont | IWixStyleFont | null {
    if (fonts?.[styleParamsFontKey]) {
      return fonts[styleParamsFontKey];
    }
    return appSettingsFont || null;
  }

  const newFonts: {
    [key in IStylesParamsFontsKeys]?: ISettingsFont | IWixStyleFont | null;
  } = {};

  (
    Object.keys(
      stylesParamFontKeyToAppSettingsMapper,
    ) as IStylesParamsFontsKeys[]
  ).forEach((styleParamsFontKey) => {
    newFonts[styleParamsFontKey] = mapFont({
      appSettingsFont: appSettings[
        stylesParamFontKeyToAppSettingsMapper[styleParamsFontKey]
      ] as ISettingsFont,
      styleParamsFontKey,
    });
  });

  return cleanNulls(newFonts);
}
